/* base.css */

.home-container {
    /* Establece la imagen de fondo y ajusta su tamaño y posición */
    background-image: url('../images/fondo-super-3.png');
    background-size: cover;
    background-position: center;
    /* Establece la altura del contenedor para ocupar toda la pantalla */
    /*height: calc(100vh - 4.2rem);*/
    min-height: calc(100vh - 4.5rem);
    /*background-color: #db043eff;*/
    /* Asegura que el contenido no se superponga al menú superior */
    /*padding-top: 64px; Ajusta este valor según la altura del menú superior*/
}

/* Estilos para el contenido dentro del contenedor principal */
.home-content {
    /* Alinea el contenido al centro verticalmente */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* Establece un ancho máximo para el contenido */
    max-width: 800px;
    margin: 0 auto; /* Centra el contenido horizontalmente */
    
}



/* Estilos para el texto */
.home-content h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
    
}

.home-content p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Asegura que esté por encima de otros elementos */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}

/* CSS para chatbot_simple.js */
.chat-simple-container {
  display: flex;
  height: 90vh; /* Altura de la ventana completa */
  background-color: #444;
  border: 1px solid #23272a;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  margin: 8px auto;
  padding: 0;
}



/* Contenedor principal del chat */
.chat-simple-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 60px); /* Ajusta el contenedor principal para dejar espacio para el footer */
  background-color: #2c2f33;
  border: 1px solid #23272a;
  border-radius: 10px;
  margin: 8px auto;
  position: relative;
  
}

/* Barra lateral */
.chat-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #2c2f33;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
  overflow: auto;
}

.chat-sidebar h3 {
  margin: 0 0 15px 0;
  font-size: 16px; /* Ajustado para mejor encaje */
  font-weight: bold;
}

.chat-sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.chat-sidebar li {
  padding: 8px 10px; /* Ajustado para mejor encaje */
  background-color: #2c2f33;
  margin-bottom: 8px; /* Ajustado para mejor encaje */
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #444; /* Añadido para resaltar los elementos de sesión */
  font-size: 14px; /* Ajustado para mejor encaje */
  
}



.chat-sidebar li.active {
  background-color: #7289da;
}
/* Barra lateral cerrada */
.chat-sidebar.closed {
  transform: translateX(-100%);
}

/* Estilo del texto de la barra lateral */
.chat-sidebar h3,
.chat-sidebar h4 {
  color: white;
  margin-bottom: 10px;
}

/* Listas de sesiones */
.chat-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.chat-sidebar ul li {
  color: white;
  padding: 10px;
  border: 1px solid #444;
  margin-bottom: 5px;
  cursor: pointer;
}

.chat-sidebar ul li:hover {
  background-color: #444;
}




/* Área principal de chat */
.chat-main {
  /* background-image: url('../images/fondo-super-3.png'); 
  background-size: cover;
  background-position: center;   */
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: linear-gradient(to bottom right, #000000 40%, #4B0000 70%, #8B0000 100%) !important;


  
}

/* Ventana de chat */
.chat-simple-window {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  /* background-image: url('../images/fondo-super-3.png'); 
   background-size: cover;
  background-position: center;  */
  
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin: 0 auto; /* Centra el elemento horizontalmente */
}





.chat-simple-window table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px auto; /* Centra la tabla horizontalmente */
  font-size: 16px;
  text-align: left;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Añade una sombra suave */
  border-radius: 5px; /* Esquinas redondeadas */
}

.chat-simple-window th, .chat-simple-window td {
  padding: 15px; /* Mayor espacio entre contenido y borde */
  border-bottom: 1px solid #ddd; /* Solo bordes inferiores para una apariencia más limpia */
}

.chat-simple-window th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* Mensajes */
.message {
  
  max-width: 100%;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 10px;
  line-height: 1.5;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
  width: fit-content; /* Ajusta el ancho al contenido */
}


.message.user {
  align-self: flex-end;
  background-color: #393b44;
  color: #fff;
  margin-left: auto;
  max-width: 70%;
  
}

.message.bot {
  align-self: flex-start;
  background-color: #606060; /* Gris medio para contraste */
  color: #fff;
  margin-right: auto;
  padding: 10px;
  border-radius: 10px;
  max-width: 100%;
}

.loader {
  border: 4px solid #f3f3f3; /* Gris claro */
  border-top: 4px solid #8a2c2c; /* Rojo (o color de tu elección) */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.thinking-message {
  font-style: italic;
  color: #8a2c2c; /* Color de texto durante el "pensando" */
  text-align: center;
  margin-top: 10px;
}

.thinking-text {
  font-size: 16px;
  color: #fff; /* Ajusta el color según tu diseño */
}

.dots::after {
  content: '';
  display: inline-block;
  width: 1em;
  text-align: left;
  animation: dots 1.5s steps(3, end) infinite;
}

@keyframes dots {
  0% { content: ''; }
  33% { content: '.'; }
  66% { content: '..'; }
  100% { content: '...'; }
}

.copy-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #d9534f; /* Rojo más claro para contraste */
  color: #fff; /* Blanco para el texto */
  border: 2px solid #c9302c; /* Bordes en un tono rojo más oscuro para definición */
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Transiciones suaves */
  position: relative;
}



/* Entrada de usuario */
.chat-input {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 80%; /* Ajusta el ancho */
  margin: 0 auto; /* Centra el elemento horizontalmente */
}


#user-input-simple {
  flex: 1;
  padding: 15px;
  border: 1px solid #555;
  border-radius: 10px;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  min-height: 50px; /* Altura mínima */
  max-height: 150px; /* Altura mínima */
  resize: none; /* Desactiva el redimensionamiento manual */
  overflow: hidden; /* Oculta la barra de desplazamiento */
  
}

.chat-input button {
  padding: 15px;
  background-color: #a70b0b;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.chat-input button:hover {
  background-color: #a70b0b;
}


/* Footer */
.footer-message {
  text-align: center;
  font-size: 12px;
  color: #f5f4f4;
  padding: 10px;
 
  
}


/* Botón de abrir/cerrar sidebar */
.toggle-sidebar-button {
  position: absolute;
  top: 105px;
  left: 15px;
  padding: 10px;
  background-color: #23272a;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1001; /* Asegura que esté por encima de otros elementos */
}




/* Área principal de chat */
.chat-main {
  margin-left: 250px; /* Espacio cuando la barra lateral está abierta */
  transition: margin-left 0.3s ease;
  padding: 15px;
  background-color: #fff;
  height: calc(100vh - 50px); /* Ajusta la altura para que no se corte el área de entrada de mensajes */
  flex-grow: 1;
  padding: 20px;
  overflow-y: hidden;
}


.chat-main.no-sidebar {
  margin-left: 0;
}

/* Ajustes específicos para el área de mensajes */
.chat-simple-window {
  height: calc(100vh - 150px); /* Ajusta para que el área de mensajes tenga suficiente espacio */
  overflow-y: auto; /* Permite desplazamiento en caso de que haya muchos mensajes */
}


body {
  overflow: hidden;
  margin: 0;
}




/* Contenedor del icono de menú */
.menu-icon {
  position: relative;
  z-index: 10; /* Asegura que el icono esté visible, pero puedes ajustar esto si es necesario */
  margin-left: 10px; /* Ajusta este margen según sea necesario para evitar la superposición */
}

/* Contenedor del texto de "Sessions" */
.sessions-text {
  margin-left: 50px; /* Aumenta el margen izquierdo para que el texto no se superponga con el icono */
  font-size: 18px; /* Ajusta el tamaño de la fuente según sea necesario */
  line-height: 40px; /* Alinea verticalmente el texto con el icono */
  color: #fff; /* Cambia el color si es necesario para mejor visibilidad */
}

/* Ajuste adicional para el contenedor de sesiones si es necesario */
.sessions-container {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #23272a;
  border-bottom: 1px solid #444;
}





/* Botón de menú (icono ☰) */
.toggle-sidebar-button {
  position: fixed;
  top: 10px;  /* Ajusta según la distancia deseada desde el borde superior */
  left: 10px;  /* Ajusta según la distancia deseada desde el borde izquierdo */
  z-index: 100; /* Asegura que el botón esté por encima de otros elementos */
  background-color: transparent; /* Fondo transparente para que el icono esté limpio */
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.create-new-conversation-button {
  position: fixed;
  top: 20px;  /* Ajusta según la distancia deseada desde el borde superior */
  left: 90px;  /* Ajusta según la distancia deseada desde el borde izquierdo */
  z-index: 100; /* Asegura que el botón esté por encima de otros elementos */
  background-color: transparent; /* Fondo transparente para que el icono esté limpio */
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}


.message-actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

.copy-button, .vote-button {
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.copy-button i, .vote-button i {
  font-size: 1.2rem; /* Ajusta el tamaño de los íconos aquí */
}

.copy-button:hover i {
  color: #0056b3;
}

.copy-button::after {
  content: '✔'; /* Puedes usar un ícono de font-awesome o un símbolo */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: transparent;
  font-size: 16px; /* Tamaño del ícono de confirmación */
  transition: color 0.3s ease, opacity 0.3s ease; /* Añadir transición de opacidad */
  opacity: 0; /* Inicialmente invisible */
}

.copy-button.copied::after {
  color: #28a745; /* Color del tick cuando se ha copiado */
  opacity: 1; /* Hacer visible el tick */
}

.vote-button {
  font-size: 1.2rem; /* Ajusta el tamaño de los íconos aquí */
}

.vote-button:hover {
  transform: scale(1.1);
}

.vote-button.active {
  color: #28a745; /* Color verde para el upvote activo */
}

.vote-button.upvote.active {
  color: #28a745; /* Verde para el upvote activo */
}

.vote-button.downvote.active {
  color: #dc3545; /* Rojo para el downvote activo */
}

.vote-button.upvote {
  color: #6c757d; /* Gris claro para el upvote inactivo */
}

.vote-button.downvote {
  color: #6c757d; /* Gris claro para el downvote inactivo */
}

/* Estilos para el contenedor de mensajes predeterminados */
.preset-messages-container {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 15px;
}

/* Estilos para los mensajes predeterminados */
.preset-messages {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.preset-message-button {
  background-color: #393b44;
  color: #fff;
  border: 1px solid #555;
  border-radius: 20px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.preset-message-button:hover {
  background-color: #4a4d57;
  border-color: #777;
}


/* Estilos para pantallas pequeñas (móviles) */
@media (max-width: 767px) {
  .chat-simple-container {
    padding-bottom: 0; /* Eliminar el espacio extra en la parte inferior */
    height: 90%; /* Ocupar toda la altura de la pantalla */
    min-height: auto;
    border-radius: 0;
    margin: 5vh 0;
    overflow: hidden; /* Evita cualquier tipo de scroll */
    position: fixed; /* Mantiene el contenedor en su lugar */
    
   
  }

  

  .chat-sidebar {
    position: absolute;
    width: 80%; /* Ocupa el 80% de la pantalla cuando está abierta */
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    padding: 20px;
    top: 0; /* Ahora la barra lateral llega hasta arriba */
    left: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  .chat-sidebar.closed {
    transform: translateX(0);
  }

  .toggle-sidebar-button,
  .create-new-conversation-button {
    top: 65px; /* Mantenemos la posición vertical */
    left: 20px; /* Mantenemos la posición horizontal */
  }

  .toggle-sidebar-button {
    left: 10px; /* Mantenemos la posición horizontal */
    top: 55px;
  }

  .create-new-conversation-button {
    left: 60px; /* Desplazamos a la derecha */
  }

  .chat-main {
    
    margin-left: 0; /* Sin margen izquierdo cuando la barra lateral está cerrada */
    height: calc(90vh - 100px); /* Ocupa toda la altura restante (sin el espacio del footer) */
    padding: 17px;
    overflow-y: hidden;
  }

  .chat-simple-window {
    height: calc(100% - 80px); /* Ocupa casi todo el espacio del .chat-main */
    max-height: none;
    width: 100%;
    border-radius: 0;
  }

  .chat-input {
    width: 100%;
    margin-top: 10px;
  }

  #user-input-simple {
    font-size: 16px; /* Aumenta el tamaño de la fuente a 16px para evitar el zoom */
  }

  .chat-input button {
    padding: 10px;
    font-size: 14px;
  }

  .message {
    max-width: 80%;
    font-size: 14px;
  }

  .preset-messages {
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
    padding-bottom: 10px;
  }

  .preset-message-button {
    flex-shrink: 0;
    font-size: 12px;
    padding: 6px 12px;
  }

  .copy-button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: none; /* Rojo más claro para contraste */
    color: #fff; /* Blanco para el texto */
    border: 2px solid none; /* Bordes en un tono rojo más oscuro para definición */
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.3s ease, border-color 0.3s ease; /* Transiciones suaves */
    position: relative;
  }

  .chat-sidebar.closed + .chat-main .copy-button {
    display: none; 
  }

  .chat-sidebar:not(.closed) + .chat-main .copy-button {
    display: block; 
  }

  .footer-message {
    font-size: 8px;
  }
}



.date-range-selector {
  margin-bottom: 10px;
}

.date-range-toggle {
  background-color: #333; /* Color gris oscuro */
  color: white; /* Texto blanco */
  border: none; /* Sin borde */
  padding: 5px 10px; /* Espaciado reducido */
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer;
  transition: background-color 0.2s; /* Transición suave */
}

.date-range-toggle:hover {
  background-color: #555; /* Gris más claro al pasar el mouse */
}

.date-inputs {
  display: flex;
  gap: 10px; /* Espacio entre los inputs */
  margin-top: 5px; /* Margen superior para los inputs */
}

.date-inputs label {
  display: flex;
  flex-direction: column;
  font-size: 14px; /* Tamaño de fuente */
  color: #ccc; /* Texto gris claro */
}

.date-inputs input {
  padding: 5px; /* Espaciado interno en los inputs */
  border: 1px solid #444; /* Borde gris oscuro */
  border-radius: 4px; /* Bordes redondeados */
  background-color: transparent; /* Sin fondo */
  color: #fff; /* Texto blanco */
}

.date-inputs input:focus {
  border-color: #555; /* Color gris más claro al enfocar */
  outline: none; /* Sin borde de enfoque */
}

.date-options {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
}

.date-option-button {
  background-color: #444;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.date-option-button:hover {
  background-color: #555;
}

.date-range-selector {
  margin-bottom: 10px;
}

.date-range-toggle {
  background-color: #333;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.date-range-toggle:hover {
  background-color: #555;
}

.date-options {
  margin-top: 5px;
}

.date-quick-options {
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
}

.date-option-button {
  background-color: #444;
  color: white;
  border: none;
  padding: 3px 8px;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.date-option-button:hover {
  background-color: #555;
}

.date-inputs {
  display: flex;
  gap: 10px;
}

.date-inputs label {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #ccc;
}

.date-inputs input {
  padding: 3px;
  border: 1px solid #444;
  border-radius: 3px;
  background-color: transparent;
  color: #fff;
  font-size: 12px;
}

.date-inputs input:focus {
  border-color: #555;
  outline: none;
}


.preset-messages-container {
  width: 100%;
}

.preset-messages-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.preset-messages-viewport {
  overflow: hidden;
  width: 100%;
  margin: 0 40px;
}

.preset-messages-track {
  display: flex;
  align-items: center; /* Esto es clave para la alineación vertical */
  gap: 8px;
  white-space: nowrap;
}

.preset-message-button {
  flex-shrink: 0;
}

.scroll-button {
  position: absolute;
  z-index: 1;
}

.scroll-left {
  left: 0;
}

.scroll-right {
  right: 0;
}




